<template>
  <div class="">
    <workspace-left-menu />
    <router-link :to="{name: 'survey.landing'}">
      <button
          class="ws-folder-button border-primary-four i-border-1 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one mt-5 w-full rounded flex items-center justify-center">
        Custom Survey
      </button>
    </router-link>
    <button
            @click="$emit('moveToList')"
            class="ws-folder-button focus:outline-none hover:bg-secondary-five text-secondary-three fs-14 font-poppins fw-600 bg-primary-four shadow-one w-full mt-9 rounded flex items-center justify-center">
      Programmatic Assessment
    </button>
    <div class="info-div">
      <div class="flex justify-between items-center cursor-pointer opacity-60 text-primary-one assess-titles font-poppins fs-14 fw-600" :class="{'opacity-100': selectedLeftItem === 'all'}" @click="$emit('toggleLeftItemSelection', 'all')">
        <span>All</span>
        <span>{{ getAllAssessment }}</span>
      </div>
      <div class="flex justify-between items-center cursor-pointer mt-8 opacity-60 text-primary-one assess-titles font-poppins fs-14 fw-600" :class="{'opacity-100': selectedLeftItem === 'own'}" @click="$emit('toggleLeftItemSelection', 'own')">
        <span>My Assessment</span>
        <span>{{ getOwnAssessment }}</span>
      </div>
      <div class="flex justify-between items-center cursor-pointer mt-8 opacity-60 text-primary-one assess-titles font-poppins fs-14 fw-600" :class="{'opacity-100': selectedLeftItem === 'invited'}" @click="$emit('toggleLeftItemSelection', 'invited')">
        <span>Invited</span>
        <span>{{ getInvitedAssessment }}</span>
      </div>
      <div class="flex justify-between items-center cursor-pointer mt-8 opacity-60 text-primary-one assess-titles font-poppins fs-14 fw-600" :class="{'opacity-100': selectedLeftItem === 'draft'}" @click="$emit('toggleLeftItemSelection', 'draft')">
        <span>Draft</span>
        <span>{{ getDraftAssessment }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LeftParent",
  props: ['assessmentScreen', 'connectionSelection', 'selectedLeftItem'],
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters({
      assessments: "assessment/assessments"
    }),
    getAllAssessment() {
      return this.getOwnAssessment + this.getInvitedAssessment;
    },
    getOwnAssessment() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user && this.assessments && this.assessments.own) {
        let assessments = this.assessments.own;
        return assessments.length;
      }
      return 0;
    },
    getInvitedAssessment() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user && this.assessments && this.assessments.invited) {
        return this.assessments.invited.length;
      }
      return 0;
    },
    getDeletedAssessments() {
      if(this.assessments && this.assessments.own) {
        let assessments = this.assessments.own.filter(item => item.deleted_at);
        return assessments.length;
      }
      return 0;
    },
    getDraftAssessment() {
      if(this.assessments && this.assessments.own) {
        let assessments = this.assessments.own.filter(item => (!item.deleted_at || false || false) && !item.is_draft);
        return assessments.length;
      }
      return 0;
    }
  },
  methods: {
    toggleSelectedOption(value) {
      this.selectedOption = value;
    },
    toggleModuleSelection(id, index) {
      this.$emit("toggleModuleSelection", id, index);
    }
  }
}
</script>

<style scoped>
.assess-titles:hover {
  opacity: 100;
}
</style>
