<template>
    <div class="relative">
      <div class="flex items-center mt-2 i-rp-layer-1">
        <div class="h-10 relative i-border-1 border-secondary-two rounded ws-search-width mr-5">
          <input
              type="text"
              class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two pl-8"
              :placeholder="searchField.placeholder"
              v-model="searchField.text"
              @click="searchField.placeholder = ''"
              v-click-outside="displaySearchPlaceholder"
          />

          <div class="mr-2 flex items-center justify-between absolute inset-y-0 left-2 pointer-events-none pr-1">
            <img
                class="w-4 h-4 opacity-70"
                src="/images/icons/search_icon.svg"
            />
          </div>
        </div>
        <button
          v-if="canCreate(project.cans)"
          @click="$emit('current', 'create')"
          class="h-10 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one rounded px-6 py-2 border border-primary-four"
        >
          Create New
        </button>
      </div>

      <!-- v-if="members.length" -->
      <div class="pl-10 mt-4" style="padding-right: 89px">
        <DataTable
            :options="options"
            :items="getAssessments"
            :headers="headers"
        >
            <template #row="{item}">
              <td class="text-left font-poppins fs-14 text-primary-one i-pl-25 fw-600 tblc1 py-6">
                {{ item.title }}
              </td>
              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc2">{{ $lodash.format(item.created_at, 'dd/MM/yyyy') }}</td>

              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc5">{{ item.created_by ? getUserName(item.created_by) : 'N/A' }}</td>
              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc3">{{ item.updated_at ? $lodash.format(item.updated_at, 'dd/MM/yyyy') : $lodash.format(item.created_at, 'dd/MM/yyyy') }}</td>
              <!-- :class="{'text-primary-four': item.invited || item.assignees.length > 0, 'text-secondary-two': item.invited || item.assignees.length <= 0}" -->
              <td v-if="isOwn(item)">
                <select class="bg-transparent" @click="updateAssessment(item)" v-model="item.is_draft">
                  <option value="2">Active</option>
                  <option value="1">Inactive</option>
                </select>
              </td>
              <td
                v-else
                class="text-left font-poppins fs-14 i-pl-25 tblc4"
              >
                <span :style="{color: getStatus(item, statusData).color}">{{ getStatus(item, statusData).name }}</span>
              </td>
              <td class="text-left font-poppins fs-14 flex items-center relative i-pl-25 py-6 gap-3">
                <img
                  class="cursor-pointer w-5 h-5 opacity-75 hover:bg-secondary-three"
                  src="/images/icons/assessment/share_outline.svg"
                  alt="icon"
                  :class="{'pointer-events-none opacity-40 cursor-not-allowed': !getAccess(item.created_by)}"
                  @click="toggleShareModal(true, item)">
                <img
                  v-if="canDelete(project.cans)"
                  class="cursor-pointer w-5 h-5 opacity-75 hover:bg-secondary-three"
                  src="/images/icons/assessment/trash.svg"
                  alt="icon"
                  :class="{'pointer-events-none opacity-40 cursor-not-allowed': !getAccess(item.created_by)}"
                  @click="deleteItem(item.id)">
                <div class="item-hover">
                  <DotVerticalIcon
                    @click.native="showMoreOpt = item.id"
                    class="w-5 h-5"
                  />
                  <!-- <img
                    class="cursor-pointer opacity-75 hover:bg-secondary-three"
                    src="/images/icons/assessment/assess_more_vertical.svg"
                     alt="icon"
                     @click="showMoreOpt = item.id"
                  > -->
                  <div class="absolute assess-more-opt py-5 px-5 bg-primary-three shadow-two rounded d-none">
                    <router-link
                      v-if="canEdit(project.cans)"
                      :to="{name: 'intel.assess.edit', params: { id: $route.params.id, assessment_id: item.id }}"
                      :class="{'text-secondary-one': getDisability(item), 'text-primary-one': !getDisability(item)}"
                      :event="!getDisability(item) ? 'click' : ''"
                    >
                      <h2 class="fs-14 font-poppins w-full cursor-pointer">Edit assessment</h2>
                    </router-link>
                    <router-link
                      :to="{name: 'intel.assess.survey', params: { id: $route.params.id, assessment_id: item.id }}"
                      :class="{'text-secondary-one': getDisability(item), 'text-primary-one': !getDisability(item)}"
                      :event="!getDisability(item) ? 'click' : ''"
                    >
                      <h2 class="fs-14 font-poppins mt-3 w-full cursor-pointer">Contributions</h2>
                    </router-link>
                  </div>
                </div>
              </td>
            </template>
        </DataTable>
      </div>
      <share-modal :dModal="shareModal" :activeAssess="activeAssess" @toggleShareModal="toggleShareModal" />
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";
import ShareModal from "./ShareModal";
import DataTable from '@/elements/table/DataTable.vue'
import useDatatable from '@/elements/table/useDatatable'

export default {
  name: "List",
  components: {ShareModal, DataTable},
  props: ['selectedLeftItem'],
  created() {
    const self = this;
  },
  data() {
    return {
      headers: [
        {
            visible: true,
            text: 'Assessment name',
            value: 'name'
        },{
            visible: true,
            text: 'Created on',
            value: ''
        },{
            visible: true,
            text: 'Created by',
            value: 'role'
        },{
            visible: true,
            text: 'Last edited',
            value: 'role'
        },{
            visible: true,
            text: 'Status',
            value: 'created_at'
        },{
            visible: true,
            text: 'Action',
            value: 'action'
        }
    ],
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      shareModal: false,
      activeAssess: null,
      showMoreOpt: null,
      statusData: {
        active: {
          name: 'Active',
          color: '#2B80AD'
        },
        inactive: {
          name: 'Draft',
          color: '#616161'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      assessments: "assessment/assessments",
      project: "project/project"
    }),
    getAssessments() {
      let assessments = this.assessments;
      if(this.selectedLeftItem === 'all') {
        assessments = this.getAllAssessment();
      } else if(this.selectedLeftItem === 'own') {
        assessments = this.getOwnAssessment();
      } else if(this.selectedLeftItem === 'draft') {
        assessments = this.getDraftAssessment();
      } else if(this.selectedLeftItem === 'invited') {
        assessments = this.getInvitedAssessment();
      }

      if(this.searchField.text) {
        return assessments.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return assessments;
    },
    getFilteredOwnData() {
      let assessments = this.assessments.own;

      if(this.searchField.text) {
        return assessments.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return assessments;
    },
    getprojects() {
      let projects = []
      if (this.assessments && this.assessments.invited && this.assessments.invited.length > 0) {
        this.assessments.invited.forEach(el => {
          if (!projects.includes(el.project_id)) {
            projects.push(el.project_id)
          }
        });
      }
      return projects
    },
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user) {
        return user;
      }
      return null;
    },
  },
  methods: {
    getStatus(item, statusData) {
      if('participation_status' in item) {
        return item.participation_status == 1 ? statusData.active : statusData.inactive
      }
      return item.invited || item.assignees > 0 ? statusData.active : statusData.inactive
    },
    displaySearchPlaceholder() {
      this.searchField = {
        placeholder: 'Search',
        text: ''
      }
    },
    getAllAssessment() {
      let data = [];
      if(this.assessments && (this.assessments.own || this.assessments.invited)) {
        data = this.assessments.own;
        this.assessments.invited.map(v => Object.assign(v, {invited: true}))
        data = [...data, ...this.assessments.invited];
      }
      return data;
    },
    getOwnAssessment() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user && this.assessments && this.assessments.own) {
        return this.assessments.own;
      }
      return [];
    },
    getInvitedAssessment() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user && this.assessments && this.assessments.invited) {
        let userId = user.id
        return this.assessments.invited
      }
      return [];
    },
    getFilteredInvitedData(project_id) {
      let assessments = this.assessments.invited.filter(el => el.project_id == project_id);
      if(this.searchField.text) {
        return assessments.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return assessments
    },
    getDeletedAssessments() {
      if(this.assessments && this.assessments.own) {
        return this.assessments.own.filter(item => item.deleted_at);
      }
      return [];
    },
    getDraftAssessment() {
      if(this.assessments && this.assessments.own) {
        return this.assessments.own.filter(item => (!item.deleted_at || false || false) && !item.is_draft);
      }
      return [];
    },
    deleteItem(id) {
      if(confirm('Are you sure want to delete this?')) {
        this.$Progress.start();
        this.$store.dispatch('assessment/deleteItem', {
          id: this.$route.params.id,
          assessment_id: id
        }).then(response => {
          this.$Progress.finish();
        });
      }
    },
    getDisability(item) {
      return !!item.deleted_at;
    },
    setAssessmentData(id) {
      this.$store.dispatch("assessment/setWorkingAssessment", id);
    },
    toggleShareModal(status, item) {
      this.shareModal = status;
      this.activeAssess = item;
    },
    getUserName(user) {
      let title = '';
      if(user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = user.email;
      }
      return title;
    },
    regularUpdate(assessmentInfo) {
      let data = {
        id: assessmentInfo.id,
        title: assessmentInfo.title,
        is_draft: assessmentInfo.is_draft
      }
      this.$store.dispatch("assessment/updateAssessment", data);
    },
    getAccess(user) {
      return user && this.getAuthUser && user.id === this.getAuthUser.id;
    },
    getProjectname(projectId) {
      return this.assessments.invited.find(el => el.project_id == projectId).project.name
    },
    toggleList(project) {
      let cls = 'project-'+project+'-data'
      let list = document.getElementsByClassName(cls)
      list.forEach(el => {
        el.classList.toggle("flex")
        el.classList.toggle("hidden")
      });
    },
    hideMoreOpt() {
      this.showMoreOpt = null;
    },
    isOwn(item) {
      let loggedInUser = JSON.parse(localStorage.getItem('_user')).id
      let creatorId = (item.created_by && item.created_by.id) ? item.created_by.id : ''
      return loggedInUser == creatorId
    },
    updateAssessment(item) {
      this.$store.dispatch("assessment/updateAssessment", {
        id: item.id,
        title: item.title,
        is_draft: item.is_draft,
        definition_id: item.definition_id,
        definition_style: item.definition_style,
        description: item.description,
      })
    }
  },
  setup() {
    const options = {
        hasCheckbox: false
    }

    return {
      options
    }
  }
}
</script>

<style scoped>
.table {
  border-collapse: separate;
  border-spacing: 0 36px;
}
td {
  border-style: none solid solid none;
}
tr td:first-child {
  border-top-left-radius: 4px;
}
tr td:last-child {
  border-top-right-radius: 4px;
}
tr td:first-child {
  border-bottom-left-radius: 4px;
}
tr td:last-child {
  border-bottom-right-radius: 4px;
}
tr td {
  border-top-style: solid;
}
tr td:first-child {
  border-left-style: solid;
}
.tbl-heading thead tr:first-of-type td {
  padding: 0;
}
.survey-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 17px;
}

.survey-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.survey-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid #414141;
}

.survey-slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 3px;
  top: 3px;
  background-color: #414141;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .survey-slider {
  background-color: transparent;
  border: 1px solid #2A93C0;
}

input:focus + .survey-slider {
  box-shadow: 0 0 1px transparent;
}
input:checked + .survey-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  left: 6px;
  background-color: #2A93C0;
}
.survey-slider.round {
  border-radius: 8px;
}
.survey-slider.round:before {
  border-radius: 50%;
}
.tblc1 {
  width: 30%;
}
.tblc2 {
  width: 16%;
}
.tblc3 {
  width: 16%;
}
.tblc4 {
  width: 8%;
}
.tblc5 {
  width: 18%;
}
.assess-icon:hover {
  opacity: 1;
}
.assess-link {
  pointer-events: none;
  cursor: not-allowed;
}
.assess-more-opt {
  right: 34px;
  top: 16px;
  width: 185px;
  z-index: 9;
}
.item-hover:hover .assess-more-opt {
  display: block;
}
</style>
