<template>
    <div class="relative">
        <div class="h-5 w-5 flex items-center">
            <CheckboxCheckedIcon @click.native="$emit('removeAll')" v-if="allSelected" class="w-5 h-5 cursorPointer" />
            <CheckboxMinusIcon @click.native="$emit('selectAll')" v-else-if="isSelected" class="w-5 h-5 cursorPointer" />
            <CheckboxIcon @click.native="$emit('selectAll')" v-else class="w-5 h-5 cursorPointer" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
    totalItem: [Number, String],
    totalSelected: [Number, String]
})

const isSelected = computed(() => props.totalSelected > 0 )
const allSelected = computed(() => (props.totalItem > 0 && props.totalItem == props.totalSelected))
</script>