<template>
  <div class="_main_wrapper relative flex">
    <left-box
      class="i-left-panel shadow-one"
      :selectedLeftItem="selectedLeftItem"
      @current="loadCurrentTab"
      @toggleLeftItemSelection="toggleLeftItemSelection"
      @moveToList="moveToList"
    />

    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
        <!-- <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title" v-if="current === 'list'">Programmatic Assessment</h2> -->
      </div>

      <RightBox
        :selectedLeftItem="selectedLeftItem"
        :createAssessAbility="createAssessAbility"
        @loadCurrentTab="loadAssessCurrentTab"
        @createAbility="createAbility"
        ref="assess_right_box"
      >
        <div class="assess-menu-box absolute -mt-8 left-20" v-if="current !== 'list'">
          <div class="flex gap-10">
            <button
                @click="toggleToConfigure()"
                :class="[current === 'create' ? 'opacity-50': 'opacity-100']"
              >
              <div class="flex items-center gap-2">
                  <div class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">1</div>
                  <span class="text-primary-one">Configure</span>
                </div>
            </button>
            <button
              @click="toggleToPreview()"
              :class="[current === 'preview' ? 'opacity-50': 'opacity-100']"
            >
              <div class="flex items-center gap-2">
                <div class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">2</div>
                Preview
              </div>
            </button>
            <button
                @click="toggleToSend()"
                :class="[current === 'message' ? 'opacity-50': 'opacity-100']"
              >
                <div class="flex items-center gap-2">
                  <div
                    class="h-5 w-5 rounded-full  border border-secondary-five fs-10 flex items-center justify-center">3</div>
                  <span class="text-primary-one">Share</span>
                </div>
            </button>
          </div>
        </div>

      </RightBox>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import LeftBox from "./LeftBox";
import RightBox from "./RightBox";

export default {
  components: {RightBox, LeftBox},
  name: 'Assessment',
  created() {
    document.title = "Assessment";
    const self = this;
    self.$Progress.start();
    if (this.workingAssessment) {
      setTimeout(function () {
        self.$refs.assess_right_box.loadCurrentTab('message');
      }, 200);
    }
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
    self.$store.dispatch("programmatic/getModules", self.$route.params.id)
        .then(response => {
          if (response) {
            self.$store.dispatch("manageProperty/loadProperties", self.modules[0].id)
          }
        });
    self.$store.dispatch("assessment/getAssessments", {
      id: self.$route.params.id
    }).then(response => {
      self.$Progress.finish();
    });
  },
  data() {
    return {
      selectedLeftItem: 'all',
      current: 'list',
      createAssessAbility: false
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      workingAssessment: "assessment/workingAssessment"
    }),
  },
  methods: {
    loadCurrentTab(value) {
      if (value === 'message') {
        if (this.workingAssessment) {
          this.$refs.assess_right_box.loadCurrentTab(value);
        }
      } else {
        this.$refs.assess_right_box.loadCurrentTab(value);
      }
    },
    moveToList() {
      this.current = 'list';
      this.$refs.assess_right_box.loadCurrentTab('list');
    },
    toggleLeftItemSelection(value) {
      this.selectedLeftItem = value;
      this.$refs.assess_right_box.loadCurrentTab('list');
    },
    loadAssessCurrentTab(value) {
      this.current = value;
    },
    toggleToConfigure() {
      this.$refs.assess_right_box.loadCurrentTab('create');
    },
    toggleToPreview() {
      if(this.current === 'send') {
        this.$router.push({name: 'intel.assess.survey', params: { id: this.$route.params.id, assessment_id: this.workingAssessment }});
      } else {
        this.$refs.assess_right_box.loadCurrentTab('preview');
      }
    },
    toggleToSend() {
      if(this.workingAssessment) {
        this.$refs.assess_right_box.loadCurrentTab('message');
      }
    },
    createAbility(val) {
      this.createAssessAbility = val;
    }
  }
}
</script>

<style scoped>
</style>
