import { ref } from 'vue'

const selected = ref([])


export default function useDatatable() {

    const handleSelection = (id) => {
        const ids = selected.value
        if(ids.includes(id)) {
            ids.splice(ids.indexOf(id), 1)
            return
        }
        ids.push(id)
    }

    const removeIds = () => {
        selected.value = []
    }

    const selectAll = (items) => {
        selected.value = items.map(item => item.id)
    }

    return {
        selected,
        handleSelection,
        removeIds,
        selectAll
    }
}