<template>
   <div class="w-80 top-0 left-12">
        <button class="mr-10 text-primary-four" @click="$emit('selectAll')" v-if="!allSelected && isSelected">Select all</button>
        <button class="mr-10 text-primary-four" @click="$emit('removeAll')" v-if="allSelected">Un-mark all</button>
        <button class="mr-10 text-primary-four" @click="$emit('deleteMultiple')" v-if="isSelected">Delete</button>
        <!-- <button class="text-primary-four" @click="$emit('addPeople')" v-if="isSelected">Add People</button> -->
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    const props = defineProps({
        totalItem: [Number, String],
        totalSelected: [Number, String]
    })
    
    const isSelected = computed(() => props.totalSelected > 0 )
    const allSelected = computed(() => (props.totalItem > 0 && props.totalItem == props.totalSelected))
    </script>