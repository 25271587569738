<template>
    <div class="sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200 sm:rounded-lg">
            <thead class="bg-transparent border-b border-secondary-two">
                <slot name="headers" :headers="headers">
                    <tr v-if="!$slots.header" class="rounded-t-lg">
                        <th v-if="hasCheckbox">
                            <div class="relative pl-4">
                                <Checkbox
                                    :totalItem="items.length"
                                    :totalSelected="selected.length"
                                    @selectAll="selectAll(items)"
                                    @removeAll="removeIds()"
                                />
                                <FloatAction
                                    v-if="hasFloatAction"
                                    class="absolute"
                                    :totalItem="items.length"
                                    :totalSelected="selected.length"
                                    @selectAll="selectAll(items)"
                                    @removeAll="removeIds()"
                                />
                            </div>
                        </th>
                        <template v-for="(header, i) in headers">
                            <th 
                                :key="i" v-if="header.visible" 
                                scope="col"
                                class="px-6 py-3 text-left  fs-14 fw-500 text-secondary-one tracking-wider"
                            >
                                <slot :name="`header-${header.value}`" :item="header">
                                    <div>
                                        {{ header.text }}
                                    </div>
                                </slot>
                            </th>
                        </template>
                    </tr>
                </slot>
            </thead>
            <tbody>
                <tr :class="[selected.includes(item.id) ? 'bg-e1' : '']" v-for="(item, i) in localItems" :key="i" class="border-b border-secondary-four">
                    <div v-if="hasCheckbox" class="pl-4 py-4">
                        <div class="h-5 w-5">
                            <CheckboxCheckedIcon v-if="selected.includes(item.id)" @click.native="handleSelection(item.id)" class="w-5 h-5" />
                            <CheckboxIcon v-else @click.native="handleSelection(item.id)" class="w-5 h-5" />
                        </div>
                    </div>
                    <slot name="row" :item="item">
                        <template v-for="({ value, visible }, j) in headers">
                            <td :key="j" v-if="visible" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <slot :name="value" :item="item">
                                    {{ item[value] }}
                                </slot>
                            </td>
                        </template>
                    </slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import useDatatable from '@/elements/table/useDatatable'
import Checkbox from '@/elements/table/Checkbox'
import FloatAction from './FloatAction.vue';

const { selected, handleSelection, removeIds, selectAll } = useDatatable()

const props = defineProps({
    headers: {
        type: Array,
        required: true
    },
    items: {
        type: Array,
        required: true
    },
    options: {
        type: Object
    }
})

const allChecked = ref(false)

const hasCheckbox = computed(() =>  props.options?.hasCheckbox || false)
const hasFloatAction = computed(() => props.options?.hasFloatAction || false)
const localItems = computed(() => {
    return props.items.map(item => {
        item.isChecked = false;
        return item;
    });
})


</script>

<style scoped>
.bg-e1{
    background-color: #E1E1E1;
}
</style>