<template>
  <div class="ml-10 mr-20">
    <div class="shadow-one bg-primary-three px-10 pb-16 rounded">
      <div class="border-b border-secondary-five" style="height: 74px">
        <!-- button will be here by using absolute -->
      </div>
      <div class="flex items-center i-mt-25 gap-6">
          <input 
            type="text"
            style="width: 620px;"
            @keyup="checkCreateAbility()" 
            class="title-field bg-transparent fs-16 h-10 font-poppins fw-600 text-primary-one placeholder-secondary-two border border-secondary-five rounded px-4" 
            placeholder="Assessment title" 
            v-model="title"
          >
          <slot name="create-btn"/>
      </div>

      <div class="flex items-center gap-3 i-mt-25">
        <textarea 
          style="width: 620px"
          @keyup="checkCreateAbility()"
          class="bg-transparent font-poppins fs-14 text-primary-one placeholder-secondary-two bld-content-area border border-secondary-five p-3 h-20 rounded" 
          placeholder="Add assessment description here" 
          v-model="description"
        ></textarea>
        <span class="text-secondary-two fs-14 fw-400">Note: Assessment will always be controlled <br> and distributed with sub level control.</span>
      </div>

      <div class="i-mt-25">
        <div class="flex mb-2 items-center">
          <h2 class="font-poppins text-primary-one fs-14"><span class="mr-3">1.</span>Select Scenario:</h2>
          <select class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600" @change="changeActiveScenario($event), checkCreateAbility()" v-model="scenario">
            <option :value="scenario.id" v-for="(scenario, index) in scenarios" :key="index">{{ scenario.title }}</option>
          </select>
        </div>
        <div class="ml-6 grey flex items-center">
          <h2 class="font-poppins text-primary-one fs-14">Choose the Module you want to Assess:</h2>
          <select class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600" @change="changeActiveModule($event), checkCreateAbility()" v-model="module">
            <option :value="module.id" v-for="(module, index) in modules" :key="index">{{ module.title }}</option>
          </select>
        </div>
      </div>
      
      <div class="i-mt-25">
        <div class="flex mb-2 items-center">
          <h2 class="font-poppins text-primary-one fs-14"><span class="mr-3">
            2.</span>Which Parameters do you want to assess?
            <!-- Setup Assessment Values: -->
          </h2>
          
        </div>
        <div class="ml-6">
          <div class="flex mb-2 items-center">
            <h2 class="font-poppins text-primary-one fs-14">Setup Assessment Values:</h2>
            <select class="fs-14 font-poppins ml-4 text-primary-one bg-transparent pr-select-dropdown fw-600" @change="changeActiveSet($event)" v-model="propset">
              <option :value="set.id" v-for="(set, sindex) in propsets" :key="sindex">{{ set.title }}</option>
            </select>
          </div>
          <div class="flex">
            <div class="border border-secondary-five rounded">
              <div 
                class="flex items-center cursor-pointer border-b border-secondary-five pl-6 pt-3 pb-3 pr-16 property" 
                v-for="(property, pindex) in properties.filter(item => !item.option || parseInt(item.option) === 1)" 
                :key="pindex" @click="togglePropertySelections(property.id), checkCreateAbility()"
              >
                <div 
                  class="ws-checkbox bg-transparent w-3 h-3 i-border-1 border-secondary-one i-mr-10"
                  :class="{'bg-primary-one': checkedPropSelected(property.id)}"
                ></div>
                <span class="fs-14 font-poppins text-primary-one opacity-75" :class="{'opacity-100': checkedPropSelected(property.id)}">{{ property.title }}</span>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="i-mt-25">
        <div class="flex mb-2 items-center gap-5">
          <h2 class="font-poppins text-primary-one fs-14"><span class="mr-3">3.</span>Select definition Set:</h2>
          <select 
            class="fs-14 font-poppins text-primary-one bg-transparent pr-select-dropdown fw-600"
            v-model="definition_id"
            placeholder="Select definition"
            @change="checkCreateAbility()"
          >
            <option disabled value="null">Select definition</option>
            <option v-for="(definition, dIndex) in getDefinitions" :value="definition.id" :key="dIndex">{{ definition.title }}</option>
          </select>
          <h2 class="text-primary-one fs-14 font-poppins">(These definitions will be used if  a property does not have an associated property set.) </h2>
        </div>
        
      </div>
      <div class="flex ml-6 gap-8  mb-2 pt-4">
        <h2 class="fw-400 font-poppins fs-14 text-primary-one text-center def-col"
            v-for="(definitionItem, dIndex) in getDefinitionItems"
            :key="dIndex">{{ definitionItem.definition }}
        </h2>
      </div>
      <!-- <p class="fs-14 text-secondary-one mb-3 ml-6 pt-4">Modify Color</p> -->
      <DefinitionColorPicker
        class="ml-6"
        :definition_styles="definition_styles"
      />  
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DefinitionColorPicker from '@/components/assessment/DefinitionColorPicker.vue'

export default {
  name: "Create",
  components: {
    DefinitionColorPicker
  },
  created() {
    this.$store.dispatch("definition/getDefinitionSets", { project_id: this.$route.params.id });
    if(this.scenarios) this.scenario = this.scenarios[0].id
    if(this.modules) this.module = this.modules[0].id
    if(this.propsets) this.propset = this.propsets[0].id
    if(this.properties) this.properties = this.propsets[0].property_collections;
    if(this.scenarios) this.title = this.scenarios[0].title;
  },
  data() {
    return {
      title: "Scenario Title",
      description: '',
      scenario: null,
      module: null,
      propset: null,
      properties: [],
      definition_id: null,
      checkedProps: [],
      definition_styles: [
        { range: '0-2', bg: '#AD2B81' },
        { range: '2-4', bg: '#ABA62B' },
        { range: '4-6', bg: '#8D8D8D' },
        { range: '6-8', bg: '#2BAD7E' },
        { range: '8-10', bg: '#2B80AD' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      scenarios: "programmatic/scenarios",
      modules: "programmatic/projectModules",
      propsets: "manageProperty/properties",
      workingAssessment: "assessment/workingAssessment",
      definitionSets: 'definition/definitionSets'
    }),
    getDefinitions() {
      return this.definitionSets.filter(defSet => defSet.items.find(definition => definition.type === 1));
    },
    getDefinitionItems() {
      if(this.definition_id) {
        let definition = this.getDefinitions.find(defSet => parseInt(defSet.id) === parseInt(this.definition_id));
        return definition.items.find(item => item.type === 1).range_with_definition;
      }
      return [];
    }
  },
  watch: {
    title: function (val) {
      this.$store.dispatch("assessment/setAssessInfo", {
        title: this.title,
        description: this.description,
        definition_id: this.definition_id
      });
    },
    description: function (val) {
      this.$store.dispatch("assessment/setAssessInfo", {
        title: this.title,
        description: this.description,
        definition_id: this.definition_id
      });
    },
    definition_id: function (val) {
      this.$store.dispatch("assessment/setAssessInfo", {
        title: this.title,
        description: this.description,
        definition_id: this.definition_id
      });
    }
  },
  methods: {
    assessmentScreen() {
      this.$store.dispatch("assessment/setWorkingAssessment", null);
      this.$emit('current', 'list')
    },
    changeActiveScenario(e) {
      this.scenario = e.target.value;
    },
    changeActiveModule(e) {
      this.module = e.target.value
      this.$store.dispatch("manageProperty/loadProperties", this.module)
      .then(response => {
                    if(response) {
                        this.propset = this.propsets[0].id
                        this.properties = this.propsets[0].property_collections
                    }
                });
    },
    changeActiveSet(e) {
      this.propset = e.target.value
      this.properties = this.propsets.find(el => el.id == this.propset).property_collections
      this.checkedProps = []
    },
    togglePropertySelections(id) {
      const self = this;
      const index = this.checkedProps.indexOf(id);
      if (index > -1) {
        this.checkedProps.splice(index, 1);
      } else {
        this.checkedProps.push(id);
      }

      let data = [];
      this.checkedProps.forEach(itemId => {
        let item = self.properties.find(item => parseInt(item.id) === parseInt(itemId));
        if(item) {
          data.push(item);
        }
      });
      this.$store.dispatch("assessment/setPropsChecked", data);
    },
    selectProperty(id) {
      this.checkedProps.push(id)
    },
    unselectProperty(id) {
      const index = this.checkedProps.indexOf(id);
      if (index > -1) {
        this.checkedProps.splice(index, 1);
      }
    },
    checkedPropSelected(id) {
      let status = this.checkedProps.indexOf(id)
      if (status != -1) {
        return true
      }
      return false
    },
    save() {
      if (this.checkedProps.length > 0) {
        this.$Progress.start();
        let obj = {}
        obj.title = this.title !== "" ? this.title : "New Assessment";
        obj.description = this.description !== "" ? this.description : "Add assessment description";
        obj.project_id = this.$route.params.id;
        obj.scenario_id = this.scenario;
        obj.module_id = this.module;
        obj.property_id = this.propset;
        obj.definition_id = this.definition_id;
        obj.definition_style = this.definition_styles;
        obj.property_collections = JSON.stringify(this.checkedProps);

        this.$store.dispatch('assessment/storeAssessment', obj)
        .then(response => {
          this.$Progress.finish();
          if(response && response.data && this.workingAssessment) {
            this.$emit('current', 'preview');
            // this.$router.push({ name: 'intel.assess.survey', params: {id: this.$route.params.id, assessment_id: this.workingAssessment} });
          }
        });
      }
      else{
        alert('Please select at least one parameter.');
        return false;
      }

    },
    sendScreen() {
      if(this.workingAssessment) {
        this.$emit('current', 'message');
      }
    },
    checkCreateAbility() {
      if(this.title && this.description && this.scenario && this.module && this.propset && this.checkedProps.length > 0 && this.definition_id) {
        this.$emit("createAbility", true);
      } else {
        this.$emit("createAbility", false);
      }
    }
  }
}
</script>

<style scoped>
.title-field {
  width: 400px;
}
.track-box {
  width: 100%;
}
.value-track {
  height: 2px;
}
.progress-track {
  height: 2px;
  background: #00A7FF;
}
.range {
  height: 2px;
  outline: none;
  -webkit-appearance: none;
}
.prop-disabled[type='range']::-webkit-slider-thumb {
  cursor: not-allowed !important;
  display: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  border-radius: 2px;
  box-shadow: 0 6px 15px rgba(35, 39, 47, 0.13);
}
.slider-control-width {
  width: 800px;
}
.def-col {
  width: 184px;
}
.property:last-child{
  border: none;
}
</style>
