<template>
    <div class="relative">
      <slot />
      <component :ref="'component_' + current"
        :is="current"
        :selectedLeftItem="selectedLeftItem"
        @current="loadCurrentTab"
        @createAbility="createAbility"
      >
        <template #create-btn>
          <button
              @click="createAssessment"
              style="width: 95px"
              class="i-w-95 h-10 fs-14 border border-primary-four rounded text-primary-one"
              :title="createAssessAbility ? 'Click to create an assessments' : 'Please select all the fields to activate the link'"
            >Save</button>
        </template>
      </component>
    </div>
</template>

<script>
import List from '@/components/assessment/List'
import Create from '@/components/assessment/Create'
import Message from "@/components/assessment/Message";
import Preview from "@/components/assessment/Preview";

export default {
  name: "DefaultScreen",
  props: ['selectedLeftItem', 'createAssessAbility'],
  components: {List, Create, Message, Preview},
  created() {

  },
  data() {
    return {
      current: 'list',
    }
  },
  methods: {
    loadCurrentTab(value) {
      this.current = value;
      this.$emit("loadCurrentTab", value);
    },
    createAssessment() {
      if(this.createAssessAbility) {
        // this.$refs.assess_right_box.createAssessment();
        this.$refs[`component_${this.current}`].save();
      } else {
        alert('Please select all the fields to create an assessment.');
      }
    },
    createAbility(val) {
      this.$emit("createAbility", val);
    }
  }
}
</script>

<style scoped>
</style>
