<template>
  <div class="ml-10 mr-20 flex">
    <div class="shadow-one assess-middle-layer bg-primary-three px-10 pb-5 rounded">
      <div class="border-b border-secondary-five" style="height: 74px">
          <!-- button will be here by using absolute -->
      </div>
      <div class="flex flex-col pt-1 pb-4 i-border-b-1 border-secondary-two border-solid mt-3 mb-5 w-full">
          <span v-if="assessInfo" class="font-poppins fw-600 text-primary-one w-full">
            <input v-model="assessInfo.title" class="bg-transparent fs-18 h-10 font-poppins fw-600 text-primary-one placeholder-secondary-two w-full" placeholder="Scenario title" type="text" disabled />
          </span>
        <p v-if="assessInfo" class="font-poppins fs-14 text-primary-one">{{ assessInfo.description }}</p>
      </div>
      <div style="height: 740px" class="overflow-y-auto ws-scrollbar pr-8">
        <div v-for="(item, index) in items" :class="{'i-mt-25': index > 0}" :key="index" class="i-border-b-1 border-solid border-secondary-five i-pb-25">
          <h2 class="fs-18 font-poppins fw-600 text-primary-one">{{ index + 1 }}. {{ item.title }}</h2>
          <p class="fs-14 text-primary-one font-poppins">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>

          <div class="space-y-2 pb-4 my-5">
            <div v-for="(property, pIndex) in checkedProps" :key="pIndex">
              <div class="flex items-center">
                <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">
                  {{ property.title }}
                </h2>
                <img class="mr-6" src="/images/line.png" alt="" />
                <PreviewProgress 
                  :property="property"
                  :definitionItems="definitionItems"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="assess-last-layer ml-10 rounded assess-root-box">
      <div class="assess-last-layer-headings flex justify-between bg-primary-three shadow-one rounded">
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="selections = 'ideas'" :class="{'opacity-100': selections === 'ideas'}">Ideas</h2>
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="selections = 'comments'" :class="{'opacity-100': selections === 'comments'}">Comments</h2>
      </div>
      <div class="flex flex-col items-center justify-center assess-right-h">
        <img src="/images/icons/assessment/none.svg" alt="image" class="no-items-img text-center">
        <h2 class="fs-20 font-roboto grey c41 mt-2 ml-2">No {{ selections === 'ideas' ? 'ideas' : 'comments' }} found</h2>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PreviewProgress from '@/components/assessment/PreviewProgress.vue'

export default {
  name: "Preview",
  components: {
    PreviewProgress,
  },

  data() {
    return {
      items: [
        { title: 'A 1001.1 Area 1' },
        { title: 'A 1001.2 Area 2' },
        { title: 'A 1001.3 Area 3' },
        { title: 'A 1001.4 Area 4' },
      ],
      values: [3, 7, 5, 4, 6, 1, 3, 5, 6, 9, 3.5],
      selections: 'ideas'
    }
  },

  computed: {
    ...mapGetters({
      checkedProps: "assessment/propsChecked",
      assessInfo: "assessment/assessInfo",
      definitionSets: 'definition/definitionSets',
    }),
    getDefinitions() {
      return this.definitionSets.filter(defSet => defSet.items.find(definition => definition.type === 1));
    },
    definitionItems() {
      if(this.assessInfo?.definition_id) {
        let definition = this.getDefinitions.find(defSet => parseInt(defSet.id) === parseInt(this.assessInfo.definition_id));
        if(definition) {
          return definition.items.find(item => item.type === 1).range_with_definition;
        }
      }
      return [];
    },

    localCheckProps() {
        if(this.checkedProps.length) {
          return this.checkedProps.map((item, i) => {
            item.progress = this.values[i]
            return item
          })
        }
        return []
      }
  },
  methods: {

  }
}
</script>

<style scoped>
.track-box {
  width: 100%;
}
.value-track {
  height: 2px;
}
.progress-track {
  height: 2px;
  background: #00A7FF;
}
.range {
  height: 2px;
  outline: none;
  -webkit-appearance: none;
}
.prop-disabled[type='range']::-webkit-slider-thumb {
  cursor: not-allowed !important;
  display: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  border-radius: 2px;
  box-shadow: 0 6px 15px rgba(35, 39, 47, 0.13);
}
</style>
