<template>
    <modal name="assess-share-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
        <div class="p-10">
            <div class="flex i-border-b-1 border-secondary-four">
                <div class="w-33 cursor-pointer border-secondary-five border-solid" :class="{'i-border-b-1': activeTab === 'all'}"
                     @click="activeTab = 'all'">
                    <h3 class="font-poppins text-primary-one fs-26 pb-5 opacity-60"
                        :class="{'opacity-100': activeTab === 'all'}">Invited </h3>
                </div>
                <div class="w-33 text-center cursor-pointer border-secondary-five border-solid" :class="{'i-border-b-1': activeTab === 'accepted'}"
                     @click="activeTab = 'accepted'">
                    <h3 class="font-poppins text-primary-one fs-26 pb-5 opacity-60"
                        :class="{'opacity-100': activeTab === 'accepted'}">Accepted </h3>
                </div>
                <div class="w-33 text-right cursor-pointer border-secondary-five border-solid" :class="{'i-border-b-1': activeTab === 'pending'}"
                     @click="activeTab = 'pending'">
                    <h3 class="font-poppins text-primary-one fs-26 pb-5 opacity-60"
                        :class="{'opacity-100': activeTab === 'pending'}">Pending </h3>
                </div>
            </div>
            <div class="modal-body-card overflow-y-auto ws-scrollbar" v-if="activeAssess">
                <table class="table-auto w-full">
                    <thead>
                    <tr>
                        <th class="text-left text-primary-one opacity-60 fs-18 font-poppins">List of Invites</th>
                        <th class="text-left text-primary-one opacity-60 fs-18 font-poppins">Status</th>
                        <th class="text-left text-primary-one opacity-60 fs-18 font-poppins" />
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in getAssignees" :key="index" class="share-item hover:bg-secondary-five rounded">
                        <td class="text-left uppercase text-primary-one opacity-60 fs-18 font-poppins">
                            <div class="flex items-center font-poppins">
                                <img src="/images/icons/assessment/assessment_demo_avatar.png" alt="" class="mr-2">
                                <span>{{ item.assignee_id ? getUserName(item) : item.email }}</span>
                            </div>
                        </td>
                        <td class="text-left text-primary-one opacity-60 fs-18 font-poppins" :class="{'accepted': item.participation_status}">{{ item.participation_status ? 'Accepted' : 'Pending' }}</td>
                        <td class="text-left text-primary-one opacity-60 fs-18 font-poppins cursor-pointer" @click="removeAssignee(item.id)">
                            <div class="">✖</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="flex justify-end i-mt-25">
                  <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal()" :disabled="loading">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "ShareModal",
        props: ['dModal', 'activeAssess'],
        data() {
            return {
                activeTab: 'all',
            }
        },
        computed: {
          getAssignees() {
             if(this.activeTab === 'all') {
                 return this.activeAssess.assignees;
             } else if(this.activeTab === 'accepted') {
                 return this.acceptedAssignee();
             } else {
                 return this.pendingAssignee();
             }
          }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.$modal.show('assess-share-modal');
                }
            }
        },
        methods: {
            closeModal() {
                this.$modal.hide('assess-share-modal');
                this.$emit("toggleShareModal", false, null);
            },
            acceptedAssignee() {
                return this.activeAssess.assignees.filter(item => item.participation_status);
            },
            pendingAssignee() {
                return this.activeAssess.assignees.filter(item => !item.participation_status);
            },
            getUserName(user) {
                let title = '';
                user = user.assignee;
                if(user && user.first_name) {
                    title = user.first_name;
                    if(user.last_name) {
                        title = title + ' ' + user.last_name;
                    }
                } else {
                    title = user.email;
                }
                return title;
            },
            removeAssignee(id) {
                const self = this;
                self.$store.dispatch("assessment/removeAssignee", {
                    id: self.$route.params.id,
                    assessment_assignee_id: id
                });

                let index = self.activeAssess.assignees.findIndex(item => parseInt(id) === parseInt(item.id));
                if(index >= 0) {
                    self.activeAssess.assignees.splice(index, 1);
                }
            }
        }
    }
</script>

<style scoped>
    .w-33 {
        width: 33.33%;
    }
    .modal-body-card {
        padding-top: 20px;
        max-height: 550px;
    }
    table th, table td {
        padding: 15px 0;
    }
    .accepted {
        color: #2A93C0;
    }
</style>
